import { SyntheticEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import ReactInputMask from "react-input-mask"

import { Key, Refresh } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import { Divider, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material"

import { sendRequest } from "../apis/lab-apis"
import Header from "../components/header/header"
import { SnackBar } from "../components/snackbar/snackbar"
import {
	ApiKeyDetailsService,
	ApiKeyDetailsServiceCustomResponseField,
	ApiKeyDetailsServiceResponseFieldType,
	useLabApi,
} from "../hooks/app/lab-api/use-lab-api"
import { useAuthentication } from "../hooks/app/useAuthentication"
import { APIExecuter } from "../pages/lab-api/components/api-executer"
import { ResponseViewer } from "../pages/lab-api/components/api-executer/response-viewer"
import { SideMenu } from "../pages/lab-api/components/side-menu"
import { ResponseQuiz } from "../pages/lab-api/response-quiz"
import { Container } from "./routes.styles"

const LabRoute = () => {
	const [tabId, setTabId] = useState("business")
	const [tabIndex, setTabIndex] = useState(0)
	const [singleAPIKey, setSingleAPIKey] = useState<string>("")
	const [responseLoading, setResponseLoading] = useState<boolean>(false)

	const { isAuthenticated, apiKey: item } = useAuthentication()

	const {
		apiKey,
		loading,
		response,
		form: {
			control,
			formState: { isValid },
		},
		snackbar,
		setSnackbar,
		executeApi,
		getDetailsApiKey,
		onCloseResponseBox,
		setSelectedService,
		setApiKey,
		services,
		setLoading,
		selectedService,
		snackbarMessage,
		snackbarType,
		apiKeyDetails,
		setResponse,
	} = useLabApi()

	const { t } = useTranslation()

	useEffect(() => {
		setApiKey(item?.apiKey)
	}, [])

	useEffect(() => {
		setLoading(true)
		getDetailsApiKey().finally(() => {
			setLoading(false)
		})
	}, [apiKey])

	const isLogged = item || isAuthenticated

	const getFormFieldSize = (index: number): "half" | "full" => {
		if (selectedService) {
			return selectedService?.requestFields?.length === 1 ||
				(index === selectedService?.requestFields?.length - 1 &&
					selectedService?.requestFields?.length % 2 !== 0)
				? "full"
				: "half"
		}
		return "full"
	}

	const handleTabChange = (event: SyntheticEvent, newValue: string) => {
		setTabId(newValue)
		switch (newValue) {
			case "business":
				setTabIndex(0)
				break

			default:
			case "development":
				setTabIndex(1)
				break
		}
	}

	const getField = (field: string) =>
		Array.isArray(selectedService?.responseFields) &&
		selectedService?.responseFields?.find((f) => f.path === field)

	const handleMapper = (type: string, value: any) => {
		const mapper = {
			date: (value: any) => {
				const date = new Date(value)
				if (isNaN(date.getTime())) return value
				return date.toLocaleDateString("pt-BR", {
					timeZone: "UTC",
				})
			},
		}

		if (mapper[type as keyof typeof mapper]) {
			return mapper[type as keyof typeof mapper](value)
		}

		return value
	}

	const handleResponseFieldValue = (
		value: any,
		type: string | undefined,
		options: { format?: string; mapping?: object },
	) => {
		if (
			type === ApiKeyDetailsServiceResponseFieldType.STRING &&
			options?.format === "mapping" &&
			options?.mapping
		) {
			const valueMapped = options.mapping[value as keyof typeof options.mapping]
			const defaultValue = options.mapping["*" as keyof typeof options.mapping]
			return valueMapped || defaultValue ? `${valueMapped || defaultValue} (${value})` : value
		}

		if (type === ApiKeyDetailsServiceResponseFieldType.STRING && options?.format) {
			return (
				<ReactInputMask
					mask={options.format}
					value={value}
					readOnly={true}
					maskChar={null}
					maskPlaceholder={null}
					style={{
						border: "none",
						fontWeight: "400",
						fontSize: "0.875rem",
						textAlign: "right",
					}}
				/>
			)
		}

		if (value === null || value === undefined) {
			return "-"
		}

		return value
	}

	const handleSideMenuItemClick = (service: ApiKeyDetailsService) => {
		setSelectedService(service)
		setResponse(null)
	}

	const isLocal = process.env.REACT_APP_IS_LOCAL === "true"

	return (
		<>
			<Header isLogged={isLogged} />
			{process.env.REACT_IS_LOCAL}
			<Container>
				<SideMenu.Root>
					{services?.map((service: ApiKeyDetailsService, index: number) => {
						const isDisabled = !service?.requestFields?.length
						const isSelected = selectedService?.serviceId === service.serviceId
						return (
							<SideMenu.Item
								onClick={() => handleSideMenuItemClick(service)}
								key={index}
								isDisabled={isDisabled}
								isSelected={isSelected}
							>
								<SideMenu.ItemContent text={service.name} />
								{isDisabled && <SideMenu.ItemTip text="Indisponível" />}
							</SideMenu.Item>
						)
					})}
				</SideMenu.Root>

				<APIExecuter.Root isLoading={loading}>
					{!services?.length && isAuthenticated && <APIExecuter.NoServiceFound />}

					{services?.length > 0 && (
						<APIExecuter.Form>
							{selectedService && (
								<>
									<APIExecuter.FormHeader
										title={selectedService.name}
										description={selectedService.description}
									>
										<APIExecuter.FormAction
											icon={PlayArrowIcon}
											onClick={() =>
												executeApi({
													apiCallback: sendRequest,
													apiKey: singleAPIKey,
													extraHeaders: isLocal
														? {
																serviceId: selectedService.serviceId,
																serviceLimitId: selectedService.serviceLimitId,
																apiKeyId: apiKeyDetails.id,
															}
														: {},
												})
											}
											isDisabled={!isValid}
										>
											{t("playground.start_api_label")}
										</APIExecuter.FormAction>
									</APIExecuter.FormHeader>

									<APIExecuter.FormBody>
										{selectedService?.requestFields?.map((field, index) => (
											<APIExecuter.FormFieldWrapper size={getFormFieldSize(index)} key={index}>
												<APIExecuter.FormField
													isRequired={field.required}
													placeholder={field.example}
													label={field?.label || field.name}
													id={field.name}
													control={control}
												/>
											</APIExecuter.FormFieldWrapper>
										))}
									</APIExecuter.FormBody>

									{services?.length > 0 && !isAuthenticated && (
										<Divider style={{ margin: "2rem 0" }} />
									)}
								</>
							)}
						</APIExecuter.Form>
					)}

					{!isAuthenticated && !services.length && (
						<APIExecuter.Form>
							<APIExecuter.FormBody>
								<APIExecuter.FormFieldWrapper size="full">
									<TextField
										id="api-key"
										label="Chave de API"
										style={{ width: "100%" }}
										placeholder="Insira sua chave de API"
										value={singleAPIKey}
										onChange={(e) => setSingleAPIKey(e.target.value)}
										type="password"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<Key />
												</InputAdornment>
											),
											endAdornment: (
												<InputAdornment position="end">
													<IconButton>
														<Refresh
															color="primary"
															onClick={() => getDetailsApiKey(singleAPIKey)}
														/>
													</IconButton>
												</InputAdornment>
											),
										}}
										variant="filled"
									/>
								</APIExecuter.FormFieldWrapper>
							</APIExecuter.FormBody>
						</APIExecuter.Form>
					)}

					{true &&
						typeof selectedService?.responseFields === "object" &&
						(selectedService?.responseFields as ApiKeyDetailsServiceCustomResponseField)
							?.customFaceId === "quiz-fraude-front" && (
							<ResponseQuiz
								answers={response?.data?.answers}
								question={response?.data?.question}
								loading={responseLoading}
								scoreClassification={response?.data?.scoreClassification}
								scoreDescription={response?.data?.scoreDescription}
								callback={async (answer) => {
									setResponseLoading(true)
									await executeApi({
										apiCallback: sendRequest,
										apiKey: singleAPIKey,
										data: {
											answer: {
												quizIdentifier: response.data.quizIdentifier,
												questionIdentifier: response.data.identifier,
												...answer,
											},
										},
										extraHeaders: isLocal
											? {
													serviceId: selectedService.serviceId,
													serviceLimitId: selectedService.serviceLimitId,
													apiKeyId: apiKeyDetails.id,
												}
											: {},
									})
									setResponseLoading(false)
								}}
							/>
						)}

					{response && Array.isArray(selectedService?.responseFields) && (
						<ResponseViewer.Root>
							<ResponseViewer.CloseIcon icon={CloseIcon} onClose={onCloseResponseBox} />

							<ResponseViewer.Tabs onTabChange={handleTabChange} activeTab={tabId}>
								<ResponseViewer.TabsTab value={"business"}>
									{t("playground.label_business_view")}
								</ResponseViewer.TabsTab>
								<ResponseViewer.TabsTab value={"development"}>
									{t("playground.label_developer_view")}
								</ResponseViewer.TabsTab>
							</ResponseViewer.Tabs>

							<ResponseViewer.SwipeableViews viewIndex={tabIndex} onViewChange={setTabIndex}>
								<ResponseViewer.TabsPanel show={tabIndex === 0}>
									<ResponseViewer.Table>
										{Object.keys(response).map((e: any, index: number) => {
											const field = getField(e) as any
											const value = field ? handleMapper(field.type, response[e]) : response[e]
											return (
												<ResponseViewer.TableRow
													key={index}
													label={
														<Tooltip title={field?.description} arrow>
															<span>{field?.label || e}</span>
														</Tooltip>
													}
													value={handleResponseFieldValue(value, field?.type, {
														format: field?.format,
														mapping: field?.mapping,
													})}
												/>
											)
										})}
									</ResponseViewer.Table>
								</ResponseViewer.TabsPanel>

								<ResponseViewer.TabsPanel show={tabIndex === 1}>
									<ResponseViewer.CodeBlock code={response} />
								</ResponseViewer.TabsPanel>
							</ResponseViewer.SwipeableViews>
						</ResponseViewer.Root>
					)}
				</APIExecuter.Root>

				<SnackBar
					type={snackbarType}
					open={snackbar}
					handleClose={() => setSnackbar(false)}
					message={snackbarMessage}
				/>
			</Container>
		</>
	)
}

export default LabRoute
