import { useFormContext } from "react-hook-form"

import { DataGridExecutionOption } from "./data-grid-execution-option"
import { SelectionExecutionOption } from "./selection-execution-option"

export const ExecutionOptionsProps = {}

export const ExecutionOptions = () => {
	const { watch } = useFormContext<any>()

	const executionOptions = watch("executionOptions")

	const multipleOptionsConfig = Array.isArray(executionOptions)

	return (
		<>
			{(multipleOptionsConfig ? executionOptions : [executionOptions]).map(
				(option: any, index: number) => {
					const params = {
						data: option,
						index: index,
						multipleOptionsConfig,
					}

					switch (option?.type) {
						case "data-grid":
							return <DataGridExecutionOption {...params} key={index} />
						case "selection":
							return <SelectionExecutionOption {...params} key={index} />
						default:
							return null
					}
				},
			)}
		</>
	)
}
