import { Card, Paper, Typography } from "@mui/material"
import styled from "styled-components"

import { maxDeviceSizeForMediaQueries } from "../../styles/devices-medias"

export const Container = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
	height: 100%;
	margin-top: 60px;
`

export const PlaygroundContent = styled.div`
	display: flex;
	height: 100%;
	width: 80%;
	flex-direction: row;
`

export const PlaygroundForm = styled.div`
	width: 50%;
	padding: 24px;
	height: 100%;
	box-sizing: border-box;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		width: 100%;
		padding: 16px;
	}
`
export const PlaygroundResponse = styled.div`
	width: 50%;
	background: ${(props) => props.theme.palette.primary.contrastText};
	border-left: 1px solid #e6e6e684;
	padding: 0px;
	box-sizing: border-box;

	@media ${maxDeviceSizeForMediaQueries.tablet} {
		width: 100%;
		position: absolute;
		right: 0;
		z-index: 100;
		height: 100%;
	}
`

export const HeaderForm = styled.div`
	width: 100%;
	margin-bottom: 16px;
`

export const HeaderContent = styled.div`
	display: flex;
	justify-content: space-between;
`

export const HeaderTitle = styled(Typography)`
	font-weight: 900;
`

export const HeaderDescription = styled(Typography)`
	margin-top: 16px !important;
`

export const BodyForm = styled.div``

export const Code = styled.div`
	padding: 8px 0;
	box-sizing: border-box;
`

export const ContainerActions = styled.div`
	width: 100%;
	background: #1e1e1e;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	justify-content: space-between;
	padding: 8px 8px;
`

export const TabContainer = styled.div``
export const ContainerClose = styled.div`
	display: none;
	justify-content: flex-end;
	padding: 0 16px;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		display: flex;
	}
`

export const HeaderResponse = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	padding: 16px 0;
	box-sizing: border-box;
	justify-content: space-between;
`

export const Label = styled.label`
	color: #dadada;
	font-size: 12px;
`

export const Select = styled.select`
	width: 30%;
	padding: 6px;
	height: 30px;
	font-size: 12px;
	background: transparent;
	color: white;
	border: 1px solid #bfbfbf37;

	&:focus {
		outline: 0;
	}
`

export const TitleResponse = styled(Typography)`
	font-size: 20px;
	margin: 0 0 16px 0;
	padding: 0;
	font-weight: 200;
	color: ${(props) => props.theme.palette.text.primary};
`

export const LimitContainer = styled.div``

export const HeaderError = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	color: #791c1c;
`

export const BoxError = styled(Card)`
	width: 100%;
	padding: 16px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		width: 100%;
	}
`

export const Box = styled(Card)`
	width: 48%;
	min-height: 70px;
	padding: 16px;
	box-sizing: border-box;

	@media ${maxDeviceSizeForMediaQueries.tablet} {
		width: 100%;
	}
`

export const TitleBox = styled.p`
	margin: 0;
	font-weight: bold;
	font-size: 16px;
`

export const DescriptionBox = styled.p`
	margin: 4px 0;
	font-size: 14px;
`

export const DescriptionErrorBox = styled.p`
	margin: 12px 0 0 2px;
	font-size: 14px;
	color: #3a0101;
`

export const ContainerBoxes = styled.div`
	width: 100%;
	height: 100%;

	@media ${maxDeviceSizeForMediaQueries.tablet} {
		gap: 12px;
	}
`

export const ContainerAnswers = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 8px 0;
`

export const Motion = styled.img`
	width: 500px;
`

export const Item = styled(Paper)`
	text-align: center;
	height: 60;
	line-height: 60px;

	&:hover {
		background: #e1e1e1;
		cursor: pointer;
		color: #2f2f2f;
	}
`

export const ContainerLoading = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	gap: 16px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	background-color: #00000061;
`

export const ResultStopWatch = styled.div`
	background: ${(props) => props.theme.palette.background.default};
	border-radius: 10px;
	padding: 16px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const TimeText = styled.p`
	text-transform: uppercase;
	font-size: 12px;
	margin: 0;
	color: ${(props) => props.theme.palette.text.primary};
`
export const TimeDescription = styled.p`
	font-size: 30px;
	margin: 8px 0;
	color: ${(props) => props.theme.palette.text.primary};
`
export const ResultContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	border: 2px solid ${(props) => props.theme.palette.primary.main};
	background: ${(props) => props.theme.palette.background.default};
	padding: 32px;
	box-sizing: border-box;
	border-radius: 10px;
	margin: auto;
	width: fit-content;
	text-align: center;
	justify-content: center;
	color: ${(props) => props.theme.palette.primary.main};
`

export const ResultText = styled(Typography)`
	font-size: 16px;
	color: ${(props) => props.theme.palette.primary.contrastText};
`

export const RoundChip = styled.div`
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: ${(props) => props.theme.palette.primary.main};
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
`
