import { useFormContext } from "react-hook-form"

import { Button, Typography } from "@mui/material"
import { DataGrid, GridToolbarContainer, GridValidRowModel } from "@mui/x-data-grid"

export type ExecutionOptionsProps = {
	index: number
	multipleOptionsConfig: boolean
	data: any
}

export const DataGridExecutionOption = ({
	index,
	multipleOptionsConfig,
	data,
}: ExecutionOptionsProps) => {
	const { setValue } = useFormContext<any>()

	const handleRowUpdate = (
		newRow: GridValidRowModel,
	): GridValidRowModel | Promise<GridValidRowModel> => {
		const updatedRow = { ...newRow, isNew: false }
		setValue(
			multipleOptionsConfig ? `executionOptions[${index}].options` : "executionOptions.options",
			data.options.map((row: any) => (row.id === newRow.id ? updatedRow : row)),
		)
		return updatedRow
	}

	function EditToolbar() {
		const changeActiveForAll = (active = true) => {
			const updatedOptions = data.options.map((option: any) => {
				option.active = active
				return option
			})
			setValue("executionOptions.options", updatedOptions)
		}

		return (
			<GridToolbarContainer>
				<Button color="secondary" onClick={() => changeActiveForAll(true)}>
					Ativar todos
				</Button>

				<Button color="secondary" onClick={() => changeActiveForAll(false)}>
					Desativar todos
				</Button>
			</GridToolbarContainer>
		)
	}

	return (
		<div style={{ width: "100%", marginBottom: "16px" }}>
			<Typography color={"secondary"} variant={"h6"}>
				{data?.title}
				<span style={{ fontSize: "13px", opacity: "0.75", marginLeft: "4px" }}>
					({data?.options.length})
				</span>
			</Typography>

			<Typography color="textPrimary" variant={"body2"} style={{ marginBottom: "16px" }}>
				{data?.description}
			</Typography>
			<DataGrid
				style={{ maxHeight: "500px" }}
				editMode="row"
				rowSelection={false}
				processRowUpdate={handleRowUpdate}
				rows={data?.options}
				columns={data?.columns}
				slots={{
					toolbar: data?.disableMassActitivationToolbar ? null : EditToolbar,
				}}
			/>
		</div>
	)
}
