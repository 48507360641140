import { useContext, useEffect, useState } from "react"

import { useTranslation } from "react-i18next"
import "./code.css"

import { Button, CircularProgress } from "@mui/material"
import { grey } from "@mui/material/colors"

import { GlobalContext } from "../../contexts/global-provider"
import { formatTimeStopWatch } from "../../utils/format"
import {
	ContainerAnswers,
	ContainerBoxes,
	ContainerLoading,
	HeaderResponse,
	ResultContainer,
	ResultStopWatch,
	ResultText,
	RoundChip,
	TimeDescription,
	TimeText,
	TitleResponse,
} from "./lab-api.styles"

type ResponseQuizParams = {
	question: string
	answers: Array<Answer>
	callback: (answer: Answer) => void
	loading: boolean
	scoreClassification?: string
	scoreDescription?: string
}

type Answer = {
	answer: string
	identifier: string
	displayAt: string
	answerAt: string
}

export const ResponseQuiz = ({
	question,
	answers,
	callback,
	loading,
	scoreClassification,
	scoreDescription,
}: ResponseQuizParams) => {
	const { t } = useTranslation()

	const { light } = useContext(GlobalContext)

	const [selected, setSelected] = useState<string>("")
	const [isStopWatchRunning, setStopWatchRunning] = useState(true)
	const [lastTime, setLastTime] = useState(0)
	const [displayAt, setDisplayAt] = useState("")

	const nowUTCBrazillianDatetime = () =>
		new Date(new Date().toISOString().replace("Z", "+03:00")).toISOString()

	useEffect(() => {
		setDisplayAt(nowUTCBrazillianDatetime())
		setStopWatchRunning(true)
	}, [answers])

	useEffect(() => {}, [lastTime])

	const onSelect = (item: Answer) => {
		callback({
			answer: item.answer,
			identifier: item.identifier,
			displayAt,
			answerAt: nowUTCBrazillianDatetime(),
		})
		setSelected(item.answer)
		setStopWatchRunning(false)
	}

	const onChangeLastTime = (miliseconds: number) => {
		if (miliseconds !== 0) {
			setLastTime(miliseconds)
		}
	}

	return (
		<ContainerBoxes>
			<HeaderResponse>
				{question && <TitleResponse variant="h5">{question}</TitleResponse>}
			</HeaderResponse>
			<ContainerAnswers>
				{answers && (
					<>
						{answers.map((e) => (
							<Button
								size={"large"}
								key={e.identifier}
								className={selected === e.answer ? "selected" : ""}
								fullWidth
								variant={"contained"}
								sx={{
									background: grey[300],
									color: grey[800],
									":hover": { background: grey[400] },
								}}
								onClick={() => onSelect(e)}
							>
								{e.answer}
							</Button>
						))}
						{/* 
						<StopWatch
							onStop={(miliseconds) => onChangeLastTime(miliseconds)}
							isRunning={isStopWatchRunning}
						/> */}
					</>
				)}
			</ContainerAnswers>

			{scoreClassification && scoreDescription && (
				<ResultContainer>
					<RoundChip>
						<ResultText variant="h4">{scoreClassification}</ResultText>
					</RoundChip>

					<span>{scoreDescription}</span>
				</ResultContainer>
			)}
			{loading && (
				<ContainerLoading>
					<CircularProgress style={{ color: "white" }} />
					<ResultStopWatch>
						<TimeText>{t("playground.response_time")}</TimeText>
						<TimeDescription>{`${formatTimeStopWatch(lastTime)} ${t(
							"playground.label_seconds",
						)}`}</TimeDescription>
						<TimeText>{t("playground.to_answer")}</TimeText>
					</ResultStopWatch>
				</ContainerLoading>
			)}
		</ContainerBoxes>
	)
}
