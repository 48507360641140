import { SyntheticEvent } from "react"
import { useFormContext } from "react-hook-form"

import { Autocomplete, Grid, TextField, Typography } from "@mui/material"

export const SelectionExecutionOption = () => {
	const { watch, setValue } = useFormContext<any>()
	const executionOptions = watch("executionOptions")

	return (
		<div style={{ width: "100%", marginBottom: "2rem" }}>
			<Typography color={"secondary"} variant={"h6"}>
				{executionOptions?.title}
				<span style={{ fontSize: "13px", opacity: "0.75" }}>
					({executionOptions?.options.length})
				</span>
			</Typography>

			<Typography color="textPrimary" variant={"body2"} style={{ marginBottom: "16px" }}>
				{executionOptions?.description}
			</Typography>

			<Grid container>
				<Grid item xs={4}>
					<Autocomplete
						disablePortal
						size={"small"}
						options={executionOptions?.options}
						getOptionLabel={(option: any) => option?.title || ""}
						onChange={(_: SyntheticEvent, o: any) => {
							setValue(
								"executionOptions.options",
								executionOptions?.options.map((option: any) => ({
									...option,
									selected: option?.id === o?.id,
								})),
							)
						}}
						defaultValue={executionOptions?.options[0]}
						value={executionOptions?.options?.find((option: any) => option.selected)}
						renderInput={(params) => (
							<TextField
								{...params}
								variant={"filled"}
								color={"secondary"}
								label={"Selecione uma opção"}
							/>
						)}
					/>
				</Grid>
			</Grid>
		</div>
	)
}
